<template>
  <Form
    @submit="onUpload"
    :validation-schema="{
      file: '',
    }"
  >
    <div>
      <h4 class="mb-6 text-secondary">Importar trabajadores de CA</h4>
    </div>
    <div class="pb-8">
      <h6 class="text-sm">Crea trabajadores en Bildin desde CA</h6>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        :disabled="loadingSave"
        class="rounded bg-primary py-2 px-6 text-white flex"
        type="submit"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Importar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { defineEmits, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["loadUsers"]);

const store = useStore();

let fileToUpload = ref(null);
let responseType = ref(null);
let responseMessage = ref(null);
let loadingSave = ref(false);

const onUpload = async () => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    await store.dispatch("importCAWorkers");

    responseType.value = "success";
    responseMessage.value = "Se importó a los trabajadores de CA con éxito";

    emit("loadUsers");

    fileToUpload.value = null;
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al subir la información";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
